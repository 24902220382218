import type { BillingAccountCard, BillingAccountId } from "@warrenio/api-spec/spec.oats.gen";
import { filterNulls } from "@warrenio/utils/collections/filterNulls";
import { mustGet } from "@warrenio/utils/collections/mustGet";
import { useAtomValue } from "jotai/react";
import { atomFamily } from "jotai/utils";
import { atom } from "jotai/vanilla";
import invariant from "tiny-invariant";
import { atomFamilyWeak } from "../../utils/jotai/atomFamilyWeak.ts";
import { queryThen } from "../../utils/query/mergeQueries.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import type { CardMethod, InvoiceMethod, LinkMethod, PaymentMethod, PaymentMethodId } from "./PaymentMethod.tsx";
import { parseAccountAdditionalData, type WithAdditionalData } from "./billingAccountQueryUtils.ts";
import { baCardQueryAtom, parseCardAdditionalData, type BillingAccountCardWithData } from "./billingCardsQuery.ts";
import type { EBillingAccount } from "./billingLogic.tsx";
import { creditCardProcessorAtom, processorsAtom } from "./paymentProcessorsLogic.tsx";

/**
 * A bound method is a payment method bound to a specific billing account or card.
 *
 * @see {@link BoundMethodBase}
 * @see {@link useAccountMethods} to get the bound payment methods for an account
 */
export type BoundMethod = BoundOtherMethod | BoundCardMethod;

interface BoundMethodBase {
    type: unknown;
    /** Unique identifier, usable as a React key */
    id: PaymentMethodId | `__card__/${BillingAccountCard["id"]}`;

    method: PaymentMethod;
}

export interface BoundOtherMethod extends BoundMethodBase {
    type: "other";

    method: LinkMethod | InvoiceMethod;
    card?: never;
}

export interface BoundCardMethod extends BoundMethodBase {
    type: "creditcard";

    method: CardMethod | InvoiceMethod;
    card: BillingAccountCardWithData;
}

/** @returns The payment methods currently associated with the account */
export function useAccountMethods(billingAccount: EBillingAccount) {
    const cardMethods: BoundCardMethod[] = useSuspenseQueryAtom(accountCardMethodsAtom(billingAccount.id));
    const otherMethods: BoundOtherMethod[] = useAtomValue(accountLinkedMethodsAtom(billingAccount.account));
    return { cardMethods, otherMethods };
}

/** The `link`-type payment methods bound to a billing account */
export const accountLinkedMethodsAtom = atomFamilyWeak((account: WithAdditionalData) =>
    atom((get) => {
        const link_methods = parseAccountAdditionalData(account)?.link_methods;
        if (!link_methods || link_methods.length === 0) {
            return [];
        }

        const processors = get(processorsAtom);
        // NB: Just ignores missing methods
        const linkedProcessors = filterNulls(link_methods.map((id) => processors.get(id as PaymentMethodId)));

        return linkedProcessors.map((method): BoundOtherMethod => {
            invariant(method.type !== "creditcard", "Expected non-card method");
            return { type: "other", id: method.id, method };
        });
    }),
);

/** The `creditcard`-type payment methods bound to a billing account */
export const accountCardMethodsAtom = atomFamily((accountId: BillingAccountId) =>
    atom((get) =>
        queryThen(get(baCardQueryAtom(accountId)), (cards) => {
            if (cards.length === 0) {
                return [];
            }

            return cards.map((card): BoundCardMethod => {
                const parsed = parseCardAdditionalData(card);

                let cardProcessor;
                if (parsed.parsedAdditionalData?.type === "paying_by_invoice") {
                    const processors = get(processorsAtom);
                    cardProcessor = mustGet(processors, "invoice", "processors") as InvoiceMethod;
                } else {
                    cardProcessor = get(creditCardProcessorAtom);
                    invariant(cardProcessor, "Credit card processor must be available when cards are present");
                }

                return {
                    type: "creditcard",
                    id: `__card__/${card.id}`,
                    method: cardProcessor,
                    card: parsed,
                };
            });
        }),
    ),
);

export const accountAllMethodsAtom = atomFamily((account: EBillingAccount) =>
    atom((get) => {
        const linkedMethods = get(accountLinkedMethodsAtom(account.account));
        const card = get(accountCardMethodsAtom(account.id));
        return queryThen(card, (cardMethods) => [...linkedMethods, ...cardMethods]);
    }),
);

export function getPrimaryCard(cardMethods: BoundCardMethod[]) {
    return cardMethods.find((m) => m.card.is_primary);
}
